import {
  AssignmentFlow,
  CourseStatus,
  LanguageName,
  ProgrammingLanguage,
  TestTaskFileKind,
  TestTaskFileType,
} from '@/controllers/graphql/generated';
import {
  FullLanguageName,
  LmsContentValidity,
  LMSEditorFormTabs,
  LmsEditorLanguages,
  TaskComplexity,
  TaskErrorCodes,
  TopicContent,
  TopicContentUpdatedAt,
} from '@/components/platform/LmsEditor/LmsEditor.typedefs';
import { EditorTabsType } from '@/components/platform/Tabs';
import { capitalize } from '@/lib/helpers/capitalize';
import { MINUTE } from '@/constants';
import { FileTypes } from '@/components/platform/Tree/Tree.typedefs';
import { SelectOptionInterface } from '@/controllers/forms/forms.typedefs';
import { CodeEditorFilesNames } from './AddCodeEditorFlowTask/CodeEditorFlowTask.typedefs';

export const timeToSolveUnit = MINUTE;

export const availableMIMETypesForCourseIcon = ['image/x-png', 'image/png', 'image/svg+xml', 'image/jpeg'];

export const acceptSizeForModuleIconInBytes = 1024 * 1024 * 2;

export const minTimeForQuestion = 15;
export const maxTimeForQuestion = 900;

export const programmingLanguageOptions = Object.values(ProgrammingLanguage)
  .map((value) => {
    const label = (value[0] ?? '').toUpperCase() + value.slice(1);

    return {
      value,
      label,
    };
  });

export const codeEditorLanguages = [
  ProgrammingLanguage.Java,
  ProgrammingLanguage.Javascript,
  ProgrammingLanguage.Python,
  ProgrammingLanguage.Sql,
  ProgrammingLanguage.Typescript,
];

export const codeEditorLangOptions = codeEditorLanguages
  .map((value) => {
    const label = capitalize(value);

    return {
      value,
      label,
    };
  });

export const markupLangOption = {
  label: capitalize(ProgrammingLanguage.Markup),
  value: ProgrammingLanguage.Markup,
};

export const complexityOptions = Object.values(TaskComplexity)
  .map((value) => ({
    value,
    label: value,
  }));

export enum ConfigOptionInhertitedFrom {
  Manually = 'manually',
}

export const initialLmsContentText = {
  [LanguageName.Uk]: '',
  [LanguageName.Ru]: '',
  [LanguageName.En]: '',
  [LanguageName.Pl]: '',
  [LanguageName.Pt]: '',
};

export const initialTaskDescription = {
  [LanguageName.Uk]: {
    content: '',
    updatedAt: null,
  },
  [LanguageName.Ru]: {
    content: '',
    updatedAt: null,
  },
  [LanguageName.En]: {
    content: '',
    updatedAt: null,
  },
  [LanguageName.Pl]: {
    content: '',
    updatedAt: null,
  },
  [LanguageName.Pt]: {
    content: '',
    updatedAt: null,
  },
};

export const initialInvalidContent: LmsContentValidity = {
  [LanguageName.Uk]: false,
  [LanguageName.Ru]: false,
  [LanguageName.En]: false,
  [LanguageName.Pl]: false,
  [LanguageName.Pt]: false,
};

export const initialValidContent: LmsContentValidity = {
  [LanguageName.Uk]: true,
  [LanguageName.Ru]: true,
  [LanguageName.En]: true,
  [LanguageName.Pl]: true,
  [LanguageName.Pt]: true,
};

export const emptyTopicContent: TopicContent = {
  [FullLanguageName.uk]: '',
  [FullLanguageName.ru]: '',
  [FullLanguageName.en]: '',
  [FullLanguageName.pl]: '',
  [FullLanguageName.pt]: '',
};

export const emptyTopicContentUpdatedAt: TopicContentUpdatedAt = {
  [FullLanguageName.uk]: null,
  [FullLanguageName.ru]: null,
  [FullLanguageName.en]: null,
  [FullLanguageName.pl]: null,
  [FullLanguageName.pt]: null,
};

export const FULL_LANGUAGE_NAME_TO_LANGUAGE_NAME: Record<
  FullLanguageName, LanguageName
> = {
  [FullLanguageName.uk]: LanguageName.Uk,
  [FullLanguageName.ru]: LanguageName.Ru,
  [FullLanguageName.en]: LanguageName.En,
  [FullLanguageName.pl]: LanguageName.Pl,
  [FullLanguageName.pt]: LanguageName.Pt,
};

export const LANGUAGE_NAME_TO_FULL_LANGUAGE_NAME: Record<
  LanguageName, FullLanguageName
> = {
  [LanguageName.Uk]: FullLanguageName.uk,
  [LanguageName.Ru]: FullLanguageName.ru,
  [LanguageName.En]: FullLanguageName.en,
  [LanguageName.Pl]: FullLanguageName.pl,
  [LanguageName.Pt]: FullLanguageName.pt,
};

export const editorLangChangeTabs: EditorTabsType[] = Object
  .values(FullLanguageName)
  .map((language) => ({
    id: language,
    title: language,
    color: 'md',
    isReadonly: false,
    shouldRenderCloseTab: false,
  }));

export const editTaskTabs: EditorTabsType[] = [
  {
    id: LMSEditorFormTabs.General,
    title: 'General',
    isReadonly: false,
    shouldRenderCloseTab: false,
    dataQa: 'lms-editor-general-tab',
  },
  {
    id: LMSEditorFormTabs.Settings,
    title: 'Settings',
    isReadonly: false,
    shouldRenderCloseTab: false,
    dataQa: 'lms-editor-settings-tab',
  },
];

export const LIMIT_ITEMS_PER_PAGE = 20;

export const quizLangChangeTabs: EditorTabsType[] = [
  {
    id: LanguageName.Uk,
    title: FullLanguageName.uk,
    isReadonly: false,
    shouldRenderCloseTab: false,
  },
  {
    id: LanguageName.Ru,
    title: FullLanguageName.ru,
    isReadonly: false,
    shouldRenderCloseTab: false,
  },
  {
    id: LanguageName.En,
    title: FullLanguageName.en,
    isReadonly: false,
    shouldRenderCloseTab: false,
  },
  {
    id: LanguageName.Pl,
    title: FullLanguageName.pl,
    isReadonly: false,
    shouldRenderCloseTab: false,
  },
  {
    id: LanguageName.Pt,
    title: FullLanguageName.pt,
    isReadonly: false,
    shouldRenderCloseTab: false,
  },
];

const INITIAL_HTML_CONTENT = '<!DOCTYPE html>\n<html lang="en">\n<head>\n  <meta charset="UTF-8">\n  <meta name="viewport" content="width=device-width, initial-scale=1.0">\n  <link rel="stylesheet" href="style.css">\n  <title>Document</title>\n</head>\n<body>\n\n  <!-- Write your code here -->\n\n</body>\n</html>\n';

const INITIAL_CSS_CONTENT = '/* Write your styles here */\n';
const INITIAL_SCRIPT_CONTENT = '// Do not modify this file\n';
const INITIAL_MARKUP_TEST_CONTENT = 'const path = require(\'path\');\n\nlet document;\nlet styles;\n\nbefore(async () => {\n  [document] = await HTML.parse(path.join(__dirname, \'index.html\'));\n  styles = await CSS.parse(path.join(__dirname, \'style.css\'));\n});\n\ndescribe(\'\', () => {\n  // Write your test here;\n});\n';

export const INITIAL_MARKUP_FILES = {
  [`/${CodeEditorFilesNames.Reference}/${CodeEditorFilesNames.Index}.${FileTypes.HTML}`]: {
    id: 1,
    kind: TestTaskFileKind.Text,
    path: `/${CodeEditorFilesNames.Reference}/${CodeEditorFilesNames.Index}.${FileTypes.HTML}`,
    content: INITIAL_HTML_CONTENT,
    isReadonly: false,
    isReadonlyForStudents: false,
    fileType: TestTaskFileType.Reference,
    pathToFile: `/${CodeEditorFilesNames.Index}.${FileTypes.HTML}`,
    isLoading: false,
  },
  [`/${CodeEditorFilesNames.Reference}/${CodeEditorFilesNames.Style}.${FileTypes.CSS}`]: {
    id: 2,
    kind: TestTaskFileKind.Text,
    path: `/${CodeEditorFilesNames.Reference}/${CodeEditorFilesNames.Style}.${FileTypes.CSS}`,
    content: INITIAL_CSS_CONTENT,
    isReadonly: false,
    isReadonlyForStudents: false,
    fileType: TestTaskFileType.Reference,
    pathToFile: `/${CodeEditorFilesNames.Style}.${FileTypes.CSS}`,
    isLoading: false,
  },
  [`/${CodeEditorFilesNames.Reference}/${CodeEditorFilesNames.Main}.${FileTypes.JS}`]: {
    id: 3,
    kind: TestTaskFileKind.Text,
    path: `/${CodeEditorFilesNames.Reference}/${CodeEditorFilesNames.Main}.${FileTypes.JS}`,
    content: INITIAL_SCRIPT_CONTENT,
    isReadonly: false,
    isReadonlyForStudents: false,
    fileType: TestTaskFileType.Reference,
    pathToFile: `/${CodeEditorFilesNames.Main}.${FileTypes.JS}`,
    isLoading: false,
  },
  [`/${CodeEditorFilesNames.Index}.${FileTypes.HTML}`]: {
    id: 4,
    kind: TestTaskFileKind.Text,
    path: `/${CodeEditorFilesNames.Index}.${FileTypes.HTML}`,
    content: INITIAL_HTML_CONTENT,
    isReadonly: false,
    isReadonlyForStudents: false,
    fileType: TestTaskFileType.Code,
    pathToFile: `/${CodeEditorFilesNames.Index}.${FileTypes.HTML}`,
    isLoading: false,
  },
  [`/${CodeEditorFilesNames.Style}.${FileTypes.CSS}`]: {
    id: 5,
    kind: TestTaskFileKind.Text,
    path: `/${CodeEditorFilesNames.Style}.${FileTypes.CSS}`,
    content: INITIAL_CSS_CONTENT,
    isReadonly: false,
    isReadonlyForStudents: false,
    fileType: TestTaskFileType.Code,
    pathToFile: `/${CodeEditorFilesNames.Style}.${FileTypes.CSS}`,
    isLoading: false,
  },
  [`/${CodeEditorFilesNames.Main}.${FileTypes.JS}`]: {
    id: 6,
    kind: TestTaskFileKind.Text,
    path: `/${CodeEditorFilesNames.Main}.${FileTypes.JS}`,
    content: INITIAL_SCRIPT_CONTENT,
    isReadonly: false,
    isReadonlyForStudents: false,
    fileType: TestTaskFileType.Code,
    pathToFile: `/${CodeEditorFilesNames.Main}.${FileTypes.JS}`,
    isLoading: false,
  },
  [`/${CodeEditorFilesNames.Main}.${CodeEditorFilesNames.Test}.${FileTypes.JS}`]: {
    id: 7,
    kind: TestTaskFileKind.Text,
    path: `/${CodeEditorFilesNames.Main}.${CodeEditorFilesNames.Test}.${FileTypes.JS}`,
    content: INITIAL_MARKUP_TEST_CONTENT,
    isReadonly: false,
    isReadonlyForStudents: false,
    fileType: TestTaskFileType.Test,
    pathToFile: `/${CodeEditorFilesNames.Main}.${CodeEditorFilesNames.Test}.${FileTypes.JS}`,
    isLoading: false,
  },
};

export const lmsEditorSupportedLanguages: LmsEditorLanguages[] = [
  LanguageName.Uk,
  LanguageName.Ru,
  LanguageName.En,
  LanguageName.Pl,
  LanguageName.Pt,
];

export const isOptionalOptions = [
  {
    label: 'No',
    value: false,
  },
  {
    label: 'Yes',
    value: true,
  },
];

export const CODE_PREFIX = '```';
export const FM_PREFIX = '---';

export const TaskFormsErrorMessageCodes = {
  [TaskErrorCodes.GithubInfoNotFound]: 'lmsEditor.githubTask.github_info_not_found_error',
  [TaskErrorCodes.NotFound]: 'lmsEditor.task.task_not_found_error',
  [TaskErrorCodes.ReadAccessDenied]: 'lmsEditor.task.read_access_denied_error',
};

export const courseStatusOptions: SelectOptionInterface<boolean>[] = [
  {
    label: CourseStatus.Inactive,
    value: false,
  },
  {
    label: CourseStatus.Active,
    value: true,
  },
];

export const defaultAssignmentFlow = {
  value: AssignmentFlow.Default,
  label: AssignmentFlow.Default,
};
